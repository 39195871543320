
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import formatDate from './../util/formatDate';
import {
  getSystemFeedbackList,
  editSystemFeedback,
  isEditAuthByCode
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  alarmSwitchOption: any
  hibernatesOption:any
  customerData: Array<any>
  editFlag: boolean
  editForm: any
  statusOption:any
  searchDataAny: any //搜索条件
}

export default defineComponent({
  name: 'SystemFeedbackTable',
  components: {},
  setup() {
    const editRef = ref()
    
        const formatDateF = formatDate.formatDateTime;
    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件

      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      statusOption:[
          {
                    value: '',
                    label: '全部',
                },
                {
                    value: 0,
                    label: '未处理',
                },
                {
                    value: 1,
                    label: '已处理',
                }
      ],
      alarmSwitchOption: [
        {
          value: '1',
          label: '低电',
        },
        {
          value: '2',
          label: '外电断电',
        },
        {
          value: '3',
          label: '盲区',
        },
        {
          value: '4',
          label: '紧急',
        },
        {
          value: '5',
          label: '移位',
        },
        {
          value: '6',
          label: '超速',
        },
        {
          value: '7',
          label: '油量检测',
        },
        {
          value: '8',
          label: '震动',
        },
      ],
      hibernatesOption: [
          
        {
          value: '1',
          label: '常在线',
        },
        {
          value: '2',
          label: '震动休眠',
        },
        {
          value: '3',
          label: '时间休眠',
        },
        {
          value: '4',
          label: '深度振动休眠',
        },
        {
          value: '5',
          label: '定时报告',
        },
        {
          value: '6',
          label: '深度振动休眠+定时报告',
        },{
          value: '7',
          label: '休眠模式',
        }
      ],
      rules: {
        name: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
      getList()
    })

    const editSave = async () => {
      editRef.value.validate(async (valid: any) => {
        if (valid) {
          
          let array: any = {comment:deviceData.editForm.comment,id:deviceData.editForm.id}
          Object.assign(array, deviceData.editForm)

          editSystemFeedback(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }

    //编辑
    const editorFun = (e: any) => {
      //console.log(e)
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true
      if (e.alarmSwitch != null && e.alarmSwitch != '') {
        deviceData.editForm.alarmSwitch = e.alarmSwitch.split(',')
      }
      if (e.hibernates != null && e.hibernates != '') {
        deviceData.editForm.hibernates = e.hibernates.split(',')
      }
      deviceData.editFlag = true
    }

    const getList = async () => {

        if(deviceData.searchDataAny.dateValue!=null){
                let startDate = formatDate.handleTime(deviceData.searchDataAny.dateValue[0],"yyyy-MM-dd HH:mm:ss");
                let endDate = formatDate.handleTime(deviceData.searchDataAny.dateValue[1],"yyyy-MM-dd");;
                 
                deviceData.searchDataAny.startDate = startDate;
                deviceData.searchDataAny.endDate = endDate+" 23:59:59";
            }else{
                delete deviceData.searchDataAny.startDate;
                delete deviceData.searchDataAny.endDate;
            }

      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getSystemFeedbackList(deviceData.searchDataAny).then(
        (res: any) => {
        //   if (res.data != null) {
        //     deviceData.customerData = res.data.list
        //     deviceData.total = res.data.total
        //     ElMessage.success('加载成功!')
        //   }
        //   deviceData.loading = false
        if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list;
                  deviceData.total = res.data.total;
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
            // ElMessage.success('加载成功!')
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }

    return {
      ...data,
      editRef,
      editSave,
      selectCustomer,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
      isEditAuthByCode,
      formatDateF
    }
  },
})
